/**
 * Ici le code javascript lié au bloc, tous les fichiers .js seront chargés peu importe le nom.
 * il sera inclu globalement dans la page normalement
 *
 * Si un code logique long est nécessaire et répété entre plusieurs blocs, alors il doit être factorisé et mis dans le js global, voir avec Paul
 *
 * Par mesure de sécurité, on englobe notre code dans une (function(){ })()
 * Cela permet d'éviter des conflits de variables entre composants et autre script
 * Si vous souhaitez utiliser une variable "global", vous pouvez faire window.mavariable
 *
 * En décomposant
 * -> Première ligne : on place une fonction entre paranthèse
 * -> Code centrale : on met le code qu'on souhaite éxécuter
 * -> Dernière ligne : on ferme la fonction, ferme la parenthèse (ce qui permet d'executer la fonction), puis on execute la fonction)
 *
 * Ici sliderEl ne sera jamais accessible depuis un autre JS
 * Si je souhaite l'utiliser depuis un autre JS je peux alors faire :
 * window.sliderEl = ...
 *
 *
 * Attention, si une vue s'éxecute plusieurs fois à bien prendre en compte cela dans votre code
 * Ici la vue n'est jamais sensée etre présente deux fois, le slider est executé 1 fois
 * Si c'était un bloc de contenu, alors on placement plus un querySelectorAll puis une boucle pour executer tous les slider de la page au niveau de ce bloc
 */
(function(){

    /*
        ! ATTENTION !
        Ce slider est présent une seul fois dans la page
        Ce code n'est pas utilisable pour du bloc
    */

    /*
    var sliderEl = document.querySelector('.strate-footer-social-wall .le-slider');

    if(sliderEl){

        var navContainerEl = document.querySelector('.strate-footer-social-wall .nav-arrows');

        var s = new thSlider(sliderEl, {
            draggable: true,
            scrollListener: true,
            oninit: function (slider) {
                if (navContainerEl) {
                    thSliderTools.onInitNav(slider, navContainerEl.children);
                }
            },
            onchange: function (slider) {
                if(navContainerEl){
                    thSliderTools.onChangeNav(slider, navContainerEl.children);
                }
            }
        });

    }
    */

})();